import {Dispatch, FC, SetStateAction, useCallback} from 'react';

import {useAppDispatch} from '../../../../../redux/store';
import {fetchSetPayment} from '../../../../../redux/thunks/payment/set-payment';
import {HOST_PAYMENT, urlNotifyPayment} from '../../../../../shared/constants/url';
import {convertPriceToString} from '../../../../../shared/utils/converter-price';
import {getIdUser, isRightTokenTime} from '../../../../../shared/utils/decoder-jwt';
import {isAuth, jwtUser} from '../../../../../shared/utils/save-local-storage';
import {Button} from '../../../../buttons';


interface ByeButtonProps{
	id: string
	setOpenPhoneWindow: Dispatch<SetStateAction<boolean>>;
	price: number
}

export const ByeButton:FC<ByeButtonProps> = ({id, setOpenPhoneWindow, price}) => {
	const dispatch = useAppDispatch();
	const idUser = getIdUser();

	const onClickBuy = useCallback(() => {
		if (!idUser || !isRightTokenTime()) {
			setOpenPhoneWindow(true);
			return;
		}
		dispatch(fetchSetPayment({
			notification_url: `${HOST_PAYMENT}${urlNotifyPayment}`,
			issuer: {Abonement: {id}},
			requester: {Registered: {id: idUser}},
			success_url: window.location.href,
			fail_url: window.location.href,
		}));
	}, [id, jwtUser(), isAuth(), idUser]);

	return (
		<Button label={`Купить за ${convertPriceToString(price)}`} bgColor={'green'} onClick={onClickBuy}/>
	);
};
