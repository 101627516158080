import {FC, memo, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';

import noImagePlaceholder from '../../../assets/img/no_image.png';
import IconSporder from '../../../components/svg-icon-components/icon-sporder';
import {PlatformSingle} from '../../../interfaces/platform';
import {ONE_ORG_NAME, SCHEDULE_PAGE_DEFAULT} from '../../../shared/constants/path';
import {generatedUrlOrgName} from '../../../shared/generated-url-org-name';
import {useResizeWindow} from '../../../shared/hooks/use-resize-window';
import {activateScroll, disabledScroll} from '../../../shared/utils/scroll-body';
import {Button} from '../../buttons';
import {FlickitySlider} from '../../flickity-slider/flickity-slider';
import {UniqueImage} from '../../images/index.type';
import {Spoiler} from '../../Spoiler/Spoiler';
import {PlatformCardContactsPage} from './components/PlatformCardContactsPage/PlatformCardContactsPage';
import {Slider} from './components/slider/slider';
import * as SC from './platform-card.style';

export interface PlatformCardProps {
	platform: PlatformSingle;
	className?: string;
	onShowMap?: () => void;
}

export const PlatformCard: FC<PlatformCardProps> = memo(({platform}) => {
	const [showSliderWindow, setShowSliderWindow] = useState(false);
	const [showImgId, setShowImgId] = useState('');

	const [checkedImg, setCheckedImg] = useState(platform.images[0]);

	const slickShowSlider = (id?: string) => {
		if (id) {
			setShowImgId(id);
		}
		setShowSliderWindow(prevState => !prevState);
		if (!showSliderWindow) {
			disabledScroll();
		} else {
			activateScroll();
		}
	};
	useEffect(() => {
		const logo = platform.images.find(i => i.info.is_logo);
		if (logo) {
			setCheckedImg(logo);
		}
	}, [platform]);
	const width = useResizeWindow();
	const isMobileMod = width <= 1040;
	const clickCheckedImg = (checkedImg: UniqueImage) => {
		setCheckedImg(checkedImg);
	};
	const orgLogo = useMemo(() => platform.org_logo.find(image => image.info.size_label === '1:1')?.info.image_link || noImagePlaceholder, [platform.id]);


	return (
		<SC.Container>
			<SC.SliderContainer>
				<SC.CheckedPhoto src={checkedImg ? checkedImg.info.image_link : noImagePlaceholder}
								 alt="Галерея зала"
								 title={'Галерея зала'}/>
				<Slider images={platform.images} clickCheckedImg={clickCheckedImg}
						showImgId={checkedImg ? checkedImg.id : ''}/>
			</SC.SliderContainer>
			<SC.TextContainer>
				<SC.PlatformContactsWrapper>
					<SC.PlatformCardMainInfo>
						<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(platform.org_name))}>
							<SC.PlatformCardTitle>{platform.org_name}</SC.PlatformCardTitle>
						</Link>
						<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(platform.org_name))}>
							<SC.PlatformCardOrganizationLogo
								src={orgLogo}
								alt={`Логотип - ${platform.org_name}`}
								title={`Логотип - ${platform.org_name}`}
							/>
						</Link>
					</SC.PlatformCardMainInfo>
					<PlatformCardContactsPage platformSingle={platform}/>
					<Link to={SCHEDULE_PAGE_DEFAULT.replace(':org_name', generatedUrlOrgName(platform.org_name))}>
						<Button label={'Посмотреть расписание услуг'} bgColor={'purple'}/>
					</Link>
				</SC.PlatformContactsWrapper>

				<SC.PlatformCardAdditionalInfo>
					<SC.ToMapLinkWrapper offset={-150} to="platform-map">
						<SC.ToMapLink>на карту</SC.ToMapLink>
						<IconSporder strokeColor="#1C8666" width={12} height={15}/>
					</SC.ToMapLinkWrapper>
					{!isMobileMod && <SC.Description>{platform.description}</SC.Description>}
					{isMobileMod && <Spoiler description={platform.description}/>}
				</SC.PlatformCardAdditionalInfo>
			</SC.TextContainer>
			{showSliderWindow &&
				<FlickitySlider slickShowSlider={slickShowSlider} showImgId={showImgId} images={platform.images}/>}
		</SC.Container>
	);
});
