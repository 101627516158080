import styled from '@emotion/styled';
import {NavLink} from 'react-router-dom';

export const Navbar  = styled.nav`
	display: none;
	@media screen and (min-width: 1080px) {
		display: flex;
		align-items: center;
		gap: 25px;
		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 25px;
		}
`;
export const LinkNav = styled(NavLink)`
	color: #363636;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	transition: 0.3s;

	@media (hover: hover) {
		&:hover {
			color: var(--text-B-hover);
		}
	}

	&.active {
		border-bottom: 1px solid var(--border-B);
		color: var(--border-B);
	}
`;
