import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {UserMeServerInfo} from '../../../interfaces/interfaces';
import {OrganizationListItem} from '../../../interfaces/organization';
import {fetchGetOrganizationsByFilter} from '../../thunks/organization/get-organizations-by-filter-thunk';
import {fetchGetOwnersOrg} from '../../thunks/organization/get-owners-org-thunk';

export interface OrgState {
    orgData: OrganizationListItem[];
    isLoading: boolean;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    allOwners: UserMeServerInfo[];
}

const initialState: OrgState = {
    orgData: [],
    status: 'idle',
    isLoading: false,
    allOwners:[],
};

export const OrgSlice = createSlice({
    name: 'OrgSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGetOrganizationsByFilter.pending.type, (state: OrgState) => {
            state.isLoading = true;
            state.status = 'loading';
        }).addCase(fetchGetOrganizationsByFilter.fulfilled.type, (state: OrgState, action: PayloadAction<OrganizationListItem[]>) => {
            state.isLoading = false;
            state.status = 'succeeded';
            state.orgData = action.payload;
        }).addCase(fetchGetOrganizationsByFilter.rejected.type, (state) => {
            state.isLoading = false;
            state.status = 'failed';
        });
        builder.addCase(fetchGetOwnersOrg.pending.type, (state: OrgState) => {
            state.isLoading = true;
            state.status = 'loading';
        }).addCase(fetchGetOwnersOrg.fulfilled.type, (state: OrgState, action: PayloadAction<UserMeServerInfo[]>) => {
            state.isLoading = false;
            state.status = 'succeeded';
            state.allOwners = action.payload;
        }).addCase(fetchGetOwnersOrg.rejected.type, (state) => {
            state.isLoading = false;
            state.status = 'failed';
        });
    },
});

export const OrgReducer = OrgSlice.reducer;
