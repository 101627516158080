import cnBind from 'classnames/bind';
import {FC, memo} from 'react';

import {displayAddress} from '../../../../../interfaces/interfaces';
import {PlatformSingle} from '../../../../../interfaces/platform';
import {cleanWebsiteUrl} from '../../../../../shared/utils/convert-url';
import {ContactCard} from '../../../../contact-card/contact-card';
import {ListWithIcons} from '../../../../list-with-icons/list-with-icons';
import IconCall from '../../../../svg-icon-components/icon-call';
import IconInstagram from '../../../../svg-icon-components/icon-instagram';
import IconLocation from '../../../../svg-icon-components/icon-location';
import IconTelegram from '../../../../svg-icon-components/icon-telegram';
import IconTime from '../../../../svg-icon-components/icon-time';
import IconWebSite from '../../../../svg-icon-components/icon-web-site';
import {WorkSchedule} from '../../../../WorkSchedule/WorkSchedule';
import styles from './index.module.css';

const cx = cnBind.bind(styles);

interface PlatformCardContactsPageType {
	platformSingle: PlatformSingle
	classNames?: string;
}

export const PlatformCardContactsPage: FC<PlatformCardContactsPageType> = memo(({platformSingle, classNames}) => {
	const contacts = platformSingle.contacts;

	return (
		<ListWithIcons
			className={cx('contacts_list', classNames)}>
			<ListWithIcons.Item icon={<IconLocation/>} contentClassName={cx('list_item_content')}>
				<p className={cx('contacts_list_item_label')}>
					{displayAddress(platformSingle.city, platformSingle.location_info.address)}
				</p>
			</ListWithIcons.Item>
			<ListWithIcons.Item icon={<IconTime/>} contentClassName={cx('list_item_content')}>
				<WorkSchedule schedule={platformSingle.schedule}/>
			</ListWithIcons.Item>
			{contacts.viber &&
				<ListWithIcons.Item icon={<IconCall/>} contentClassName={cx('contacts_list_item_content')}>
					<p className={cx('contacts_list_item_label')}>{contacts.viber}</p>
				</ListWithIcons.Item>
			}
			{contacts.instagram.length > 0 &&
				<ContactCard icon={<IconInstagram/>} label={'Instagram'} link={contacts.instagram[0]}/>
			}
			{contacts.telegram.length > 0 &&
				<ContactCard icon={<IconTelegram/>} label={'Telegram'} link={contacts.telegram[0]}/>
			}
			{contacts.web_site.length > 0 &&
				<ListWithIcons.Item icon={<IconWebSite/>} contentClassName={cx('contacts_list_item_content')}>
					<a href={contacts.web_site[0]} className={cx('contacts_list_item_label', 'link')}
					   target="_blank">
						{cleanWebsiteUrl(contacts.web_site[0])}
					</a>
				</ListWithIcons.Item>
			}
		</ListWithIcons>
	);
});
