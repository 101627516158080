import {FC, memo, useCallback, useMemo} from 'react';
import {Link, NavLink} from 'react-router-dom';

import not_logo from '../../../assets/img/no_image.png';
import {ServiceListItem} from '../../../interfaces/service';
import {howOrder} from '../../../pages/schedule-page/utils/how-order-info';
import {ageView} from '../../../pages/service-list-page/constants';
import {ONE_ORG_NAME, SERVICE_PAGE} from '../../../shared/constants/path';
import {SERVICE_KIND_LABEL_MAP} from '../../../shared/constants/service/kind';
import {generatedUrlOrgName} from '../../../shared/generated-url-org-name';
import {convertPriceToString} from '../../../shared/utils/converter-price';
import {abonementsMinPrice, useServicePrice} from '../../../shared/utils/service/use-service-price';
import {showSex} from '../../../shared/utils/show-sex';
import {ButtonGroupSettingService} from '../../buttons';
import {ButtonNew, ButtonWithLineBottom} from '../../buttons-new';
import * as SC from './card-service.style';
import {ButtonsNavigates} from './components/buttons-navigates/buttons-navigates';

interface Props {
	service: ServiceListItem;
	onUpdate?: () => void;
	onClickCheckedServices?: () => void;
	isAdmin?: boolean;
	isAbonement?: boolean;
	checkedServices?: boolean;
}

export const CardService: FC<Props> = memo(({
												service, onUpdate, isAdmin, isAbonement, onClickCheckedServices,
												checkedServices,
											}) => {
	const serviceLogo = useMemo(() => service.logo.find(image => image.info.size_label === '16:9')?.info.image_link, []);
	const orgLogo = useMemo(() => service.org_info.logo.find(image => image.info.size_label === '1:1')?.info.image_link, []);
	const priceInfo = useServicePrice(service);
	const pathToServicePage = `${SERVICE_PAGE}?service_id=${service.id}&sports=${service.sports[0]}&service_type=${service.service_type}&platform_id=${service.platform_info.platform_id}`;
	const order_policy = service.order_policy;
	const howOrderText = howOrder({
		contacts: service.platform_info.contacts,
		allow: order_policy.allows,
		trial: order_policy.trial_order
	});
	const price = useMemo(() => abonementsMinPrice({
		priceInfo,
		allows: service.order_policy.allows,
		abonements: service.abonements
	}), [service.id]);

	const labels: string[] = [
		...(service.sex !== 'Both' ? [showSex({
			sex: service.sex,
			ageFrom: service.age_from,
			ageTo: service.age_to
		})] : []),
		...(service.age_from || service.age_to ? [ageView(service.age_from, service.age_to)] : []),
		...(service.entry_level ? [service.entry_level] : []),
	];

	const orderWithTrial = useCallback(() => {
		sessionStorage.setItem('scrollToMap', 'true');
		localStorage.setItem('trial_order', service.id);
	}, []);

	return (
		<SC.ServiceCard activeCard={service.active && !checkedServices}>
			<SC.HeaderCard>
				<SC.ServiceTypeTitle
					changeFont={service.service_type.length < 23}>{service.service_type}</SC.ServiceTypeTitle>
				{labels.length > 0 && <SC.DataServiceHeader>
					({labels.join(', ')})
				</SC.DataServiceHeader>}
				<SC.ServiceTypeTitle
					changeFont={service.sports.join(', ').length < 23}>{service.sports.join(', ')}</SC.ServiceTypeTitle>
			</SC.HeaderCard>
			<SC.Logo src={serviceLogo || not_logo} alt="логотип услуги"/>
			<SC.OrgInfoWrapper>
				<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(service.org_info.org_name))}>
					<SC.PlatformLogoWrapper>
						<SC.OrgLogo src={orgLogo || not_logo} alt={'логотип организации'}/>
						<SC.OrgName>{service.org_info.org_name}</SC.OrgName>
					</SC.PlatformLogoWrapper>
				</Link>
				<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(service.org_info.org_name))}>
					<SC.Address>{service.platform_info.city}, {service.platform_info.location_info.address}</SC.Address>
				</Link>
			</SC.OrgInfoWrapper>
			<SC.TypeServiceWrapper>
				<SC.ServiceType>
					<SC.ServiceTypeLabel>Тип услуги</SC.ServiceTypeLabel>
					<SC.Type>{SERVICE_KIND_LABEL_MAP[service.service_kind]}</SC.Type>
				</SC.ServiceType>
				{order_policy.trial_order !== null &&
					<NavLink to={pathToServicePage}>
						<ButtonWithLineBottom
							click={orderWithTrial}
							label={service.order_policy.trial_order === 0
								? 'Бесплатное пробное занятие'
								: `Пробное занятие ${convertPriceToString(order_policy.trial_order)}`}/></NavLink>}
			</SC.TypeServiceWrapper>
			<SC.PriceWrapper>
				<SC.Price>{price}</SC.Price>
				{howOrderText !== null && <SC.HowOrder>{howOrderText}</SC.HowOrder>}
			</SC.PriceWrapper>
			{isAbonement
				? <ButtonNew label={checkedServices ? 'Удалить' : 'Выбрать'} className={SC.Button}
							 onClick={onClickCheckedServices}/>
				: <ButtonsNavigates service={service}/>}
			{(isAdmin && onUpdate) && <ButtonGroupSettingService service={service} onUpdate={onUpdate}/>}
		</SC.ServiceCard>
	);
});
