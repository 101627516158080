import {createSlice} from '@reduxjs/toolkit';

import {Img, UserWithPlatformInfo} from '../../interfaces/interfaces';
import {OrganizationListItem, OrganizationSingle, Records} from '../../interfaces/organization';
import {fetchGetModeratorsByOrganizationTotal} from '../thunks/organization/get-moderators-by-organization-total-thunk';
import {fetchGetOrgCreations} from '../thunks/record/get-applications-orgs-record';

export interface OrganizationState {
	organizationNames: {org_name: string}[];
	organizations: OrganizationListItem[];
	allModeratorsOrg: UserWithPlatformInfo[];
	totalCount: number;
	checkedOrganization: OrganizationSingle | null;
	checkedOrganizationUpdate: OrganizationSingle | null;
	singleOrganization: OrganizationSingle | null;
	photoPlatformChecked: Img[];
	photoOrgChecked: Img[];
	organizationsTotal: number;
	orgsCreations: Records[]
}

const initialState: OrganizationState = {
	organizationNames: [],
	organizations: [],
	orgsCreations: [],
	allModeratorsOrg: [],
	totalCount: 0,
	checkedOrganization: null,
	checkedOrganizationUpdate: null,
	singleOrganization: null,
	photoPlatformChecked: [],
	photoOrgChecked: [],
	organizationsTotal: 0,
};

export const organizationSlice = createSlice({
	name: 'organizationSlice',
	initialState,
	reducers: {
		setOrganizationsTotal: (state: OrganizationState, action) => {
			state.organizationsTotal = action.payload.data;
		},
		setOrganizationNames: (state: OrganizationState, action) => {
			state.organizationNames = action.payload.data;
		},
		setPhotoOrgChecked: (state: OrganizationState, action) => {
			state.photoOrgChecked = [...action.payload.data];
		},
		setPhotoPlatformChecked: (state: OrganizationState, action) => {
			state.photoPlatformChecked = [...action.payload.data];
		},
		setCheckedOrganization: (state: OrganizationState, action) => {
			state.checkedOrganization = action.payload;
		},
		setOrganizations: (state: OrganizationState, action) => {
			state.organizations = [...action.payload.data];
		},
		setModeratorsOrg: (state: OrganizationState, action) => {
			state.allModeratorsOrg = [...action.payload.data];
			// state.totalCount = action.payload.totalCount;
		},
		resetData: (state: OrganizationState) => {
			state.allModeratorsOrg = [];
		},
		resetCheckedOrganization: (state: OrganizationState) => {
			state.checkedOrganization = null;
		},
		resetPhotoOrgChecked: (state: OrganizationState) => {
			state.photoOrgChecked = [];
		},
		setSingleOrganization: (state: OrganizationState, action) => {
			state.singleOrganization = {...action.payload.data};
		},
		resetSingleOrganization: (state: OrganizationState)=>{
			state.singleOrganization = null;
		},
		setCheckedOrganizationUpdate: (state:OrganizationState, action) => {
			state.checkedOrganizationUpdate = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchGetOrgCreations.fulfilled, (state, action) => {
			state.orgsCreations = action.payload?.data;
		});
		builder.addCase(fetchGetModeratorsByOrganizationTotal.fulfilled, (state, action) => {
			state.totalCount = action.payload?.data;
		});
	}
});

export const {
	setOrganizationsTotal,
	setOrganizationNames,
	setPhotoPlatformChecked,
	setPhotoOrgChecked,
	setCheckedOrganizationUpdate,
	setCheckedOrganization,
	resetSingleOrganization,
	resetData,
	resetPhotoOrgChecked,
	resetCheckedOrganization,
	setOrganizations,
	setModeratorsOrg,
	setSingleOrganization,
} = organizationSlice.actions;
