import {AbonementsListFilterKey, ServiceListFilterKey} from '../../interfaces/service';
import {ANY_ACTIVITY_OPTION} from '../../shared/constants/service/filters';

export const DEFAULT_SERVICE_LIST_LIMIT = 20;
export const UNLIMITED = 'Не ограничено';

export const SERVICE_LIST_FILTERS = {
	[ServiceListFilterKey.sports]: ServiceListFilterKey.sports,
	[ServiceListFilterKey.service_type]: ServiceListFilterKey.service_type,
	[ServiceListFilterKey.service_kind]: ServiceListFilterKey.service_kind,
	[ServiceListFilterKey.org_name]: ServiceListFilterKey.org_name,
	[ServiceListFilterKey.activity]: ServiceListFilterKey.activity,
	[ServiceListFilterKey.platform_id]: ServiceListFilterKey.platform_id
} as const;


export const DEFAULT_FILTERS_DATA = {
	[SERVICE_LIST_FILTERS.sports]: '',
	[SERVICE_LIST_FILTERS.service_type]: '',
	[SERVICE_LIST_FILTERS.service_kind]: '',
	[SERVICE_LIST_FILTERS.org_name]: '',
	[SERVICE_LIST_FILTERS.activity]: ANY_ACTIVITY_OPTION.value,
	[SERVICE_LIST_FILTERS.platform_id]: '',
	limit: '8'
};
export const ABONEMENTS_LIST_FILTERS = {
	[AbonementsListFilterKey.service_type]: AbonementsListFilterKey.service_type,
	[AbonementsListFilterKey.kind]: AbonementsListFilterKey.kind,
	[AbonementsListFilterKey.sports]: AbonementsListFilterKey.sports,
	[AbonementsListFilterKey.platform_id]: AbonementsListFilterKey.platform_id,
	[AbonementsListFilterKey.org_name]: AbonementsListFilterKey.org_name,
	[AbonementsListFilterKey.unlimited]: AbonementsListFilterKey.unlimited,
	[AbonementsListFilterKey.price_from]: AbonementsListFilterKey.price_from,
	[AbonementsListFilterKey.price_to]: AbonementsListFilterKey.price_to,
	[AbonementsListFilterKey.limit]: AbonementsListFilterKey.limit,
} as const;
export const ABONEMENTS_DEFAULT_FILTERS_DATA = {
	[ABONEMENTS_LIST_FILTERS.platform_id]: ABONEMENTS_LIST_FILTERS.platform_id,
	[ABONEMENTS_LIST_FILTERS.org_name]: ABONEMENTS_LIST_FILTERS.org_name,
	[ABONEMENTS_LIST_FILTERS.unlimited]: ABONEMENTS_LIST_FILTERS.unlimited,
	[ABONEMENTS_LIST_FILTERS.sports]: ABONEMENTS_LIST_FILTERS.sports,
	[ABONEMENTS_LIST_FILTERS.kind]: ABONEMENTS_LIST_FILTERS.kind,
	[ABONEMENTS_LIST_FILTERS.service_type]: ABONEMENTS_LIST_FILTERS.service_type,
	[ABONEMENTS_LIST_FILTERS.price_from]: ABONEMENTS_LIST_FILTERS.price_from,
	[ABONEMENTS_LIST_FILTERS.price_to]: ABONEMENTS_LIST_FILTERS.price_to,
	[ABONEMENTS_LIST_FILTERS.limit]: ABONEMENTS_LIST_FILTERS.limit,
};
export const DEFAULT_FILTERS_ABONEMENTS_DATA = {
	[ABONEMENTS_DEFAULT_FILTERS_DATA.platform_id]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.org_name]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.unlimited]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.sports]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.kind]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.service_type]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.price_from]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.price_to]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.limit]: '3',
};
export const getYearsStringFrom = (count: number): string => {
	const absCount = Math.abs(count) % 100;
	const lastDigit = absCount % 10;

	let suffix = '';

	if (absCount > 10 && absCount < 20) {
		suffix = 'лет';
	} else {
		switch (lastDigit) {
			case 1:
				suffix = 'год';
				break;
			case 2:
			case 3:
			case 4:
				suffix = 'года';
				break;
			default:
				suffix = 'лет';
		}
	}

	return `${count} ${suffix}`;
};
export const getYearsStringTo = (years: number)=> {
	if (years === 1) {
		return '1 года';
	}
		return `${years} лет`;

};

export const ageView = (ageFrom: number | null, ageTo: number | null): string => {
	if (ageFrom !== null && ageTo !== null) {
		return `${ageFrom} - ${getYearsStringTo(ageTo)}`;
	}
	if (ageTo !== null) {
		return `до ${getYearsStringTo(ageTo)}`;
	}
	if (ageFrom !== null) {
		return `от ${getYearsStringFrom(ageFrom)}`;
	}
	return '';
};
