import {FC, useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {AbonementType} from '../../../interfaces/abonement';
import {ControlButtons} from '../../../pages/abonements/all-abonements/components/control-buttons/control-buttons';
import {setCheckedAbonementUpdate} from '../../../redux/reducers/abonement-reducer';
import {setScheduleEditorTime} from '../../../redux/reducers/schedule-editor-settings-daily-reducer';
import {useAppDispatch} from '../../../redux/store';
import {fetchRemoveAbonement} from '../../../redux/thunks/abonements/remove-abonement';
import {fetchSetPayment} from '../../../redux/thunks/payment/set-payment';
import {ADMIN, ADMIN_SETTING_PLATFORMS, UPDATE_ABONEMENT} from '../../../shared/constants/path';
import {HOST_PAYMENT, urlNotifyPayment} from '../../../shared/constants/url';
import {convertPriceToString} from '../../../shared/utils/converter-price';
import {isRightTokenTime} from '../../../shared/utils/decoder-jwt';
import {isPlatformAbonement} from '../../../shared/utils/is-platform-abonement';
import {isAuth, isJwt} from '../../../shared/utils/save-local-storage';
import {activateScroll, disabledScroll} from '../../../shared/utils/scroll-body';
import {getAbonementTime} from '../../../shared/utils/time/converter-time';
import {getMonthsLabel, getWeeksLabel} from '../../../shared/utils/transform-text-label';
import {ModalPhoneOrder} from '../../modal-phone-order/modal-phone-order';
import IconTime from '../../svg-icon-components/icon-time';
import {IconLock} from '../../svg-icon-components/Lock';
import * as SC from './abonement-new.style';
import {ByeButton} from './components/bye-button/bye-button';
import {DetailWrapper} from './components/detail-wrapper/detail-wrapper';
import {ModalDetails} from './components/modal-details-abonement/modal-details-abonement';
import {Name} from './components/name/name';
import {OrgInfo} from './components/org-info/org-info';

interface AbonementNewProps {
	abonement: AbonementType;
	refetch?: () => void;
	showButtonsControl?: boolean;
}


export const AbonementNew: FC<AbonementNewProps> = ({abonement, refetch, showButtonsControl}) => {
	const BG_ICON = 'var(--yellow)';
	const dispatch = useAppDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const [showDetailsAbonement, setShowDetailsAbonement] = useState<AbonementType | null>(null);
	const [phone, setPhone] = useState('');
	const [openPhoneWindow, setOpenPhoneWindow] = useState(false);
	const navigate = useNavigate();

	const onClickBye = () => {
		const phoneNumber = phone.replace(/[\s()\-]/g, '');
		dispatch(fetchSetPayment({
			notification_url: `${HOST_PAYMENT}${urlNotifyPayment}`,
			issuer: {Abonement: {id: abonement.id}},
			requester: {Anonym: {phone: phoneNumber}},
			// TODO: provide a success and fail urls
			success_url: window.location.href,
			fail_url: window.location.href,
		}));
		setOpenPhoneWindow(false);
	};
	const changePhone = useCallback((e: string) => {
		setPhone(e);
	}, [phone]);
	const closeWindowPhone = useCallback(() => {
		setOpenPhoneWindow(false);
	}, [openPhoneWindow]);
	const closeWindow = useCallback(() => {
		setShowDetailsAbonement(null);
		setShowDetails(false);
		activateScroll();
	}, []);
	const clickShowDetails = () => {
		setShowDetailsAbonement(abonement);
		setShowDetails(true);
		disabledScroll();
	};
	const clickUpdateAbonement = () => {
		dispatch(setCheckedAbonementUpdate(abonement));
		dispatch(setScheduleEditorTime({['startAt']: abonement.info.working_from}));
		dispatch(setScheduleEditorTime({['endAt']: abonement.info.working_to}));
		navigate(`${ADMIN}${ADMIN_SETTING_PLATFORMS}${UPDATE_ABONEMENT}`, {replace: true});
	};

	const clickDeleteAbonement = () => {
		dispatch(fetchRemoveAbonement({
			id: abonement.id || '',
			platform_id: isPlatformAbonement(abonement.info.issuer) ? abonement.info.issuer.Platform : '',
		})).unwrap().then(() => {
			if (refetch) {
				refetch();
			}
		});
	};

	const uniqueServices = useMemo(() => abonement.services.filter((service, index, self) =>
		index === self.findIndex((s) => s.service_type.trim() === service.service_type.trim())
	), [abonement]);
	const orgLogo = useMemo(() => abonement.org_info.logo.find(image => image.info.size_label === '1:1')?.info.image_link, [abonement.id]);
	const timeLabel = getAbonementTime(abonement);

	return (
		<SC.Container showButtonsControl={showButtonsControl}>
			<SC.ContentWrapper>
				{showButtonsControl && <ControlButtons clickUpdateAbonement={clickUpdateAbonement}
													   clickDeleteAbonement={clickDeleteAbonement}/>}
				<Name title={abonement.info.name}/>
				<SC.Hr/>
				<OrgInfo logo={orgLogo} orgName={abonement.org_info.org_name}
						 address={isPlatformAbonement(abonement.info.issuer) && abonement.platform_info
							 ? `${abonement.platform_info.city}, ${abonement.platform_info.location_info.address}` : 'Клубная карта'}/>
				<SC.DataContainer>
					<DetailWrapper label={'Количество занятий'} icon={<IconLock backgroundColor={BG_ICON}/>}
								   text={abonement.info.limit !== null ? abonement.info.limit.toString() : 'Безлимитно'}
								   unLimit={abonement.info.limit === null}/>
					<DetailWrapper label={'Длительность абонемента'} icon={<IconTime backgroundColor={BG_ICON}/>}
								   text={'Month' in abonement.info.duration
									   ? `${abonement.info.duration.Month} ${getMonthsLabel(abonement.info.duration.Month)}`
									   : `${abonement.info.duration.Week} ${getWeeksLabel(abonement.info.duration.Week)}`}/>
					<SC.TextHoverWrapper>
						{timeLabel &&
							<DetailWrapper label={'Время для посещения'} icon={<IconTime backgroundColor={BG_ICON}/>}
										   text={timeLabel}/>}
					</SC.TextHoverWrapper>
				</SC.DataContainer>
				{uniqueServices.length > 0 && <SC.HasAbonement>Доступно в абонементе:</SC.HasAbonement>}
				<SC.ListServices>
					{uniqueServices.map(i => <li key={i.id}>{i.service_type}</li>)}
				</SC.ListServices>
			</SC.ContentWrapper>
			<SC.ButtonsWrapper>
				{abonement.payment_method === 'CASH'
					? <SC.Price>Цена: {convertPriceToString(abonement.info.price)}</SC.Price>
					:
					<ByeButton id={abonement.id} setOpenPhoneWindow={setOpenPhoneWindow} price={abonement.info.price}/>}
				<SC.DetailsButton onClick={clickShowDetails}>Подробности абонемента</SC.DetailsButton>
			</SC.ButtonsWrapper>
			{openPhoneWindow &&
				<ModalPhoneOrder closeWindow={closeWindowPhone} orderClick={onClickBye}
								 onChangePhone={changePhone}
								 description={(isAuth() && isJwt() && !isRightTokenTime())
									 ? 'Время сеанса истекло. Авторизуйтесь снова или введите номер телефона'
									 : undefined} titleButton={'Купить'}/>}
			{(showDetails && showDetailsAbonement !== null) &&
				<ModalDetails services={abonement.services} abonement={showDetailsAbonement} closeWindow={closeWindow}
							  setOpenPhoneWindow={setOpenPhoneWindow}
							  setShowDetailsAbonement={setShowDetailsAbonement}/>}
		</SC.Container>
	);
};
