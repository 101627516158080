// export const HOST_REGISTER = 'http://127.0.0.1:10000';
// export const HOST_CRM = 'http://127.0.0.1:10002';
// export const HOST_SEARCH_ENGINE = 'http://127.0.0.1:10004';
// export const HOST_SEARCH_USERS = 'http://127.0.0.1:10005';
// export const HISTORY_USER = 'http://127.0.0.1:10006';

// for production

export const HOST_REGISTER = 'https://sporder.by:10000';
export const HOST_PAYMENT = 'https://sporder.by:10001';
export const HOST_CRM = 'https://sporder.by:10002';
export const HOST_SEARCH_ENGINE = 'https://sporder.by:10004';
export const HOST_SEARCH_USERS = 'https://sporder.by:10005';
export const HISTORY_USER = 'https://sporder.by:10006';
export const HOST_STATS = 'https://sporder.by:10007';

// for dev

// export const HOST_REGISTER = 'https://sporder.by:10020';
// export const HOST_PAYMENT = 'https://sporder.by:10021';
// export const HOST_CRM = 'https://sporder.by:10022';
// export const HOST_SEARCH_ENGINE = 'https://sporder.by:10024';
// export const HOST_SEARCH_USERS = 'https://sporder.by:10025';
// export const HISTORY_USER = 'https://sporder.by:10026';
// export const HOST_STATS = 'https://sporder.by:10027';

export const telegramUrl = 'https://t.me/';
export const instagramUrl = 'https://instagram.com/';
export const webSiteUrl = 'https://';

export const urlRegisterUser = '/register/user/complete';
export const urlRegisterUserStep1 = '/register/user';
export const urlAuthUser = '/login';
export const urlUpdatePlatform = '/update';
export const urlGetAllUsers = '/list/all/user';
export const getAllUsersTotalCount = '/total/list/all/user';
export const getUserCommentsPlatform = '/list/comments';
export const getUserCommentsOrg = 'list/org/comments';
export const getUserCommentsTotalCountPlatform = '/total/list/comments';
export const getUserCommentsTotalCountOrg = '/total/list/org/comments';
export const addUserComments = '/add/comments';
export const updateUserComments = 'update/list/comments';
export const deleteUserComments = 'remove/list/comments';
export const urlGetUserRole = '/list/roles';
export const urlGetUserFullRole = '/list/roles/full';
export const urlAddRoleUser = '/add/role';
export const urlGetWorkloadStatus = '/workload/status';
export const urlGetWorkload = '/workload';
export const urlRemoveRoleUser = '/remove/role';
export const urlUpdateNickname = '/update/nickname';
export const urlUpdatePassword = '/update/password';
export const urlUpdateUserData = '/update/user';
export const urlGetVisitedOrgs = '/visited/orgs';
export const urlGetTotalVisitedOrgs = '/total/visited/orgs';
export const urlGetVisitedPlatforms = '/visited/platforms';
export const urlGetTotalVisitedPlatforms = '/total/visited/platforms';
export const urlGetVisitedServices = '/visited/services';
export const urlGetTotalVisitedServices = '/total/visited/services';
export const urlGetUserById = '/me';
export const urlAuthRecoverUser = '/login/recover';
export const urlRecoverComplete = '/login/recover/complete';
export const urlAddUserInBlackList = '/add/blacklisted';
export const urlRemoveUserInBlackList = '/remove/blacklisted';
export const urlGetAllUsersBlackList = 'list/blacklisted';
export const urlGetAllUsersBlackListTotalCount = 'total/list/blacklisted';
export const urlPostRegisterOrgRequest = '/register/org/request';

// order
export const urlPutOrderClose = '/order/close';
export const urlGetOrderAttach = '/order/attach/list';
export const urlRemoveOrderAttach = '/order/attach/remove';
export const urlPutOrderAttach = '/order/attach';
export const urlPutOrderConfirm = '/order/confirm';
export const urlPutOrderActivate = '/order/activate';
export const urlUserClosestOrder = '/order/closest/';
export const urlSetOrder = '/order/book';
export const urlPutOrderClaim = '/order/claim';
export const urlGetOrders = '/list/orders';
export const urlGetTotalOrders = '/total/list/orders';
export const urlGetUsersByOrder = '/list/users';

// schedule
export const urlGetSchedule = '/schedule';

// services
export const urlGetServiceRecommendations = '/list/recommendations';
export const urlGetServicesByFilter = '/list/services';
export const urlGetAllServices = '/list/services/all';
export const urlSingleService = '/single/service/';
export const urlAddService = '/add/service';
export const urlUpdateService = '/update/service';
export const urlGetServicesTotalByFilter = '/total/list/services';
export const urlDeactivateService = '/deactivate/service';
export const urlRemoveService = '/remove/service';
export const urlRecoverService = '/restore/service';

// dependency
export const urlRemoveDependency = '/remove/service/dependency';
export const urlUpdateDependency = '/update/service/dependency';
export const urlServicesByFilterParams = '/list/services/origins';
export const urlServicesDependency = '/list/service/dependency/';
export const urlServicesDependencyAllowed = '/list/services/dependency/allowed/';
export const urlServicesByFilterParamsDeps = '/total/list/service/dependency/';
export const urlServicesByFilterOriginsDeps = '/total/list/services/origins';
export const urlServicesByFilterAllowedDeps = '/total/list/services/dependency/allowed/';

// platforms
export const urlGetPlatformsByFilter = '/list/platforms';
export const urlGetAllPlatforms = '/list/all/platforms';
export const urlRegisterPlatform = '/register/platform';
export const urlGetPlatformById = '/single/platform';
export const urlGetPlatformsTotalByFilter = '/total/list/platforms';
export const urlRemovePlatform = '/remove/platform';
export const urlRecoverPlatform = '/restore/platform';
export const urlNotificationsPlatform = '/platform/notifications';
export const urlDeactivatePlatform = '/deactivate/platform';

// organization
export const urlGetOrganizationNamesByFilter = '/list/all/orgs/name';
export const urlRegisterOrg = '/register/org';
export const urlGetOrganizationsByFilter = '/list/all/orgs/info';
export const urlUpdateOrganization = '/update/org';
export const urlGetOrganizationByName = '/single/organization';
export const urlGetOwnersOrg = '/owners';
export const urlGetOrganizationsTotalByFilter = 'total/list/all/orgs/info';
export const urlDeactivateOrg = '/deactivate/org';
export const urlRemoveOrg = '/remove/org';
export const urlRecoverOrg = '/restore/org';
export const urlGetModeratorsByOrganization = '/moderators';
export const urlGetModeratorsTotalCount = '/total/moderators';

// img //
export const urlAddImg = '/add/image';
export const urlGetImg = '/image/';
export const urlRemoveImg = '/remove/image';
export const urlGetAvatars = '/avatars';
export const urlUploadImg = '/upload';

// city //
export const urlAddCity = '/add/city';
export const urlRemoveCity = '/remove/city';
export const urlGetAllCity = '/list/all/city';
// sport //
export const urlGetAllSport = '/list/all/sport';
export const urlPostAddSport = '/add/sport';
export const urlRemoveSport = '/remove/sport';
export const urlUpdateSport = '/update/sport';
// service-type //
export const urlAddServiceType = '/add/service/type';
export const urlRemoveServiceType = 'remove/service/type';
export const urlGetAllTypesService = '/list/all/service/type';
export const urlUpdateServiceType = '/update/service/type';

// abonements//
export const urlTotalListAbonements = '/total/list/abonements';
export const urlListAbonements = '/list/abonements';
export const urlRemoveAbonement = '/remove/abonement';
export const urlUpdateAbonement = '/update/abonement';
export const urlAddAbonement = '/add/abonement';

// payment
export const urlPayment = 'add/payment';
export const urlNotifyPayment = '/update/payment/status';

// record
export const urlGetOrgCreations = '/list/org/creations';
export const urlApproveNewOrg = '/approve/new/org';

// stats
export const urlGetStats = '/get/stats';
export const urlPostStatsNotify = '/notify/stats';

// filters
export const singleFiltersPlatformOrg = '/single/filters';
export const listAllOrgFilters = '/list/all/filters';
