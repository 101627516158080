import styled from '@emotion/styled';

export const Container = styled.button`
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 16px;
	border-radius: 6px;
	background-color: var(--green);
	transition: 0.3s;
	color: var(--white-color);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	width: fit-content;

	@media (hover: hover) {
		&:hover {
			background-color: var(--green-hover);
		}
	}
`;
