export const showSex = ({ageFrom, ageTo, sex}: { ageFrom: number | null, ageTo: number | null, sex: string }) => {

	if(ageFrom === null || ageTo === null){
		return sex === 'Male' ? 'Мужчины' : (sex === 'Female' ? 'Женщины' : 'Мужчины и Женщины');
	}
	if((ageFrom < 12 && ageTo > 12) || (ageFrom >= 12 && ageTo < 18)){
		return sex === 'Male' ? 'Юноши' : (sex === 'Female' ? 'Девушки' : 'Юноши и Девушки');
	}
	if (ageTo < 12) {
		return sex === 'Male' ? 'Мальчики' : (sex === 'Female' ? 'Девочки' : 'Мальчики и Девочки');
	}
	return sex === 'Male' ? 'Мужчины' : (sex === 'Female' ? 'Женщины' : 'Мужчины и Женщины');
};
