import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {AllServiceTypeParams, GetOrganizationsByFiltersParams, GetPlatformsByFilterParams, GetSportTypesByFilterParams} from '../api/query-params';
import {ServiceType, SportType} from '../interfaces/interfaces';
import {IPlatform} from '../interfaces/platform';
import {messageErrServiceType, messageErrSportType} from '../shared/constants/message-user';
import {
	HOST_SEARCH_ENGINE,
	urlGetAllPlatforms,
	urlGetAllSport,
	urlGetAllTypesService,
	urlGetOrganizationNamesByFilter,
	urlGetPlatformsByFilter,
} from '../shared/constants/url';
import {onGetQueryStarted} from './utils';

export const searchApi = createApi({
	reducerPath: 'searchApi',
	baseQuery: fetchBaseQuery({baseUrl: HOST_SEARCH_ENGINE}),
	endpoints: (build) => ({
		getAllSports: build.query<string[], GetSportTypesByFilterParams>({
      query: (params) => {
				return {url: urlGetAllSport, params};
			},
			transformResponse: (response: {sports: SportType[]}) => {
				return response.sports.map((sport: SportType) => sport.sport);
			},
			onQueryStarted: onGetQueryStarted(messageErrSportType)
    }),
		getAllServiceTypes: build.query<ServiceType[], AllServiceTypeParams>({
      query: (params) => {
				return {url: urlGetAllTypesService, params};
			},
			transformResponse: (response: {service_types: ServiceType[]}) => {
				return response.service_types;
			},
			onQueryStarted: onGetQueryStarted(messageErrServiceType)
    }),
		getAllOrganizationsNames: build.query<string[], GetOrganizationsByFiltersParams>({
      query: (params) => {
				return {url: urlGetOrganizationNamesByFilter, params};
			},
			transformResponse: (response: {orgs: {org_name: string}[]}) => {
				return response.orgs.map(o=>o.org_name);
			},
			onQueryStarted: onGetQueryStarted()
    }),
		getAllPlatforms: build.query<IPlatform[], GetPlatformsByFilterParams>({
      query: (params) => {
				return {url: urlGetAllPlatforms, params};
			},
			transformResponse: (response: {platforms: IPlatform[]}) => {
				return response.platforms;
			},
			onQueryStarted: onGetQueryStarted()
    }),
		getPlatforms: build.query<IPlatform[], GetPlatformsByFilterParams>({
			query: (params) => {
				return {url: urlGetPlatformsByFilter, params};
			},
			transformResponse: (response: {platforms: IPlatform[]}) => {
				return response.platforms;
			},
			onQueryStarted: onGetQueryStarted()
		}),
	})
});

export const {
	useGetAllSportsQuery,
	useGetAllServiceTypesQuery,
	useGetAllOrganizationsNamesQuery,
	useGetAllPlatformsQuery,
	useGetPlatformsQuery
} = searchApi;

