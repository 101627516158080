import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {CloseOrderParams} from '../api/query-params';
import {messageErrCloseOrder, messageSuccessUpdatePlatform} from '../shared/constants/message-user';
import {HOST_CRM, urlPutOrderClose} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {onPutQueryStarted} from './utils';

export const crmApi = createApi({
	reducerPath: 'crmApi',
	baseQuery: fetchBaseQuery({
		baseUrl: HOST_CRM,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Bearer ${jwtUser()}`);
			return headers;
		}
	}),
	tagTypes: ['Order'],
	endpoints: (build) => ({
		closeOrder: build.query<void, CloseOrderParams>({
      query: (body) => {
				return {url: urlPutOrderClose, method: 'PUT', body};
			},
			onQueryStarted: onPutQueryStarted(messageErrCloseOrder, messageSuccessUpdatePlatform )
    }),
	})
});

export const {useLazyCloseOrderQuery} = crmApi;

