import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

interface ContainerStyle{
	borderColor: 'yellow' | 'green' | 'purple';
}

export const Container = styled(Link)<ContainerStyle>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4D4D4D;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 8px;
    border: 2px solid ${props => props.borderColor === 'yellow' ? 'var(--yellow)' : (props.borderColor === 'green' ? 'var(--green)' : 'var(--purple)')};
    background-color: var(--white-color);
    transition: 0.3s;

    @media (hover: hover) {
        &:hover {
            background-color: ${props => props.borderColor === 'yellow' ? '#F5E8D4' : (props.borderColor === 'green' ? 'var(--Green-Solid-50)' : 'var(--button-Hover-B-Secondary)')};
            color: var(--text-Strong);
        }
    }
`;
