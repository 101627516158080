import {FC, memo, ReactNode} from 'react';

import * as SC from './contact-card.style';

interface Props{
	link: string;
	icon: ReactNode;
	label: string;
	classNameLink?: string;
}

export const ContactCard:FC<Props> = memo(({link, icon, label, classNameLink}) => {
	return (
		<SC.Wrapper>
			{icon}
			<SC.LabelLink href={link} target={'_blank'} className={classNameLink}>{label}</SC.LabelLink>
		</SC.Wrapper>
	);
});
