import {OrderType} from '../../../interfaces/enums';
import {Contacts} from '../../../interfaces/interfaces';

interface ContactInfo {
	buttonText: string;
	contactLink: string | null;
	bookingText: string;
}

export const howOrderInfo = (contacts: Contacts): ContactInfo => {
	let buttonText = 'Телефон';
	let contactLink: string | null = null;
	let bookingText = 'по телефону';

	if (contacts?.viber && contacts.viber.length > 0) {
		buttonText = 'Телефон';
		contactLink = contacts.viber[0];
	} else if (contacts?.telegram && contacts.telegram.length > 0) {
		buttonText = 'Телеграм';
		contactLink = contacts.telegram[0];
		bookingText = 'через Телеграм';
	} else if (contacts?.instagram && contacts.instagram.length > 0) {
		buttonText = 'Инстаграм';
		contactLink = contacts.instagram[0];
		bookingText = 'через Инстаграм';
	} else if (contacts?.web_site && contacts.web_site.length > 0) {
		buttonText = 'Сайт';
		contactLink = contacts.web_site[0];
		bookingText = 'через сайт';
	}

	return {buttonText, contactLink, bookingText};
};


export const howOrder = ({allow, trial, contacts}: { contacts: Contacts, allow: `${OrderType}`, trial: number | null }) => {

	if (allow === OrderType.view) {
		return `Бронирование только ${howOrderInfo(contacts).bookingText}`;
	}
	if(allow === OrderType.abonementOnly && trial === null) {
		return 'Только в составе абонемента';
	}
	return null;
};
