import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {GetAllModeratorParams} from '../../../api/query-params';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetModeratorsByOrganizationTotal = createAsyncThunk(
	'organizationSlice/fetchGetModeratorsByOrganizationTotal',
	async (params: GetAllModeratorParams, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const responseTotalCount = await crmPlatformAPI.getModeratorsTotalCount(params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (responseTotalCount.status === requestCodes.success) {
				return {status: responseTotalCount.status, data: responseTotalCount.data.total};
			}

			if (responseTotalCount.status === requestCodes.emptyData) {
				return {status: responseTotalCount.status, data: 0};
			}
		} catch (error) {
			return handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
