import {RootState} from '../store';

export const selectOrganizations = (state: RootState) => state.organizationState.organizations;
export const allModeratorsOrgSelector = (state: RootState) => state.organizationState.allModeratorsOrg;
export const allModeratorsTotalCountSelector = (state: RootState) => state.organizationState.totalCount;
export const checkedOrganizationSelector = (state: RootState) => state.organizationState.checkedOrganization;
export const checkedUpdateOrganizationSelector = (state: RootState) => state.organizationState.checkedOrganizationUpdate;
export const imgCheckedPlatformSelector = (state: RootState) => state.organizationState.photoPlatformChecked;
export const imgCheckedOrgSelector = (state: RootState) => state.organizationState.photoOrgChecked;
export const selectOrganizationNames = (state: RootState) => state.organizationState.organizationNames;
export const selectOrganizationsTotal = (state: RootState) => state.organizationState.organizationsTotal;
export const selectSingleOrganization = (state: RootState) => state.organizationState.singleOrganization;
export const selectOrganizationImages = (state: RootState) => state.organizationState.singleOrganization?.images;
export const orgsCreationsSelector = (state: RootState) => state.organizationState.orgsCreations;
export const allOwnersOrg = (state: RootState) => state.OrgReducer.allOwners;
