import styled from '@emotion/styled';

export const OrgInfoWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export const OrgName = styled.h3`
	font-weight: 600;
	font-size: 20px;
`;
export const Adress = styled.h4`
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
`;
export const OrgNameLocationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
`;

export const LogoOrg = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
`;
