import copy from 'copy-to-clipboard';
import {some} from 'lodash';
import {FC, useCallback} from 'react';

import {Contacts} from '../../../interfaces/interfaces';
import {cleanWebsiteUrl} from '../../../shared/utils/convert-url';
import {ContactCard} from '../../contact-card/contact-card';
import {ListWithIcons} from '../../list-with-icons/list-with-icons';
import IconCall from '../../svg-icon-components/icon-call';
import IconInstagram from '../../svg-icon-components/icon-instagram';
import IconTelegram from '../../svg-icon-components/icon-telegram';
import IconWebSite from '../../svg-icon-components/icon-web-site';
import {Tooltip} from '../../tooltip/tooltip';
import * as SC from './contacts-list.style';

interface OrganizationCardContactsListProps {
	contacts?: Contacts;
}

export const ContactsList: FC<OrganizationCardContactsListProps> = ({contacts}) => {
	const hasContacts = some(contacts, (value) => value.length > 0);

	const onCopyPhone = useCallback(() => {
		if (contacts === undefined || contacts.viber.length === 0) {
			return;
		}
		copy(contacts.viber[0]);
	}, [contacts?.viber]);

	if (!contacts || !hasContacts) {
		return null;
	}

	return (
		<ListWithIcons className={SC.ContactsList}>
			{contacts.viber.length > 0 ? (
				<ListWithIcons.Item
					icon={<IconCall/>}
					contentClassName={SC.ContactsListItemContent}
					onClick={onCopyPhone}
				>
					<SC.ContactsListItemLabel data-tooltip-id="phone-tooltip">
						{contacts.viber}
					</SC.ContactsListItemLabel>
					<Tooltip
						id="phone-tooltip"
						content="Скопировано"
						openOnClick={true}
						delayHide={800}
						openEvents={{click: true}}
						closeEvents={{click: true, mouseleave: true}}
					/>
				</ListWithIcons.Item>
			) : null}
			{contacts.instagram.length > 0 ? (
				<ContactCard icon={<IconInstagram/>} label={'Instagram'} link={contacts.instagram[0]}
							 classNameLink={SC.LinkStyle}/>
			) : null}
			{contacts.telegram.length > 0 ? (
				<ContactCard icon={<IconTelegram/>} label={'Telegram'} link={contacts.telegram[0]}
							 classNameLink={SC.LinkStyle}/>
			) : null}
			{contacts.web_site.length > 0 ? (
				<ListWithIcons.Item icon={<IconWebSite/>} contentClassName={SC.ContactsListItemContent}>
					<SC.Link
						href={contacts.web_site[0]}
						target="_blank"
						rel="noopener noreferrer"
					>
						{cleanWebsiteUrl(contacts.web_site[0])}
					</SC.Link>
				</ListWithIcons.Item>
			) : null}
		</ListWithIcons>
	);
};
