import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import Flickity from 'react-flickity-component';

import {SearchPlatformAPI} from '../../../../../api/search-platform-api';
import {AbonementType} from '../../../../../interfaces/abonement';
import {StillAbonementCard} from '../still-abonement-card/still-abonement-card';
import * as SC from './slider-abonements.style';

interface SliderAbonementsProps {
	platformId: string | undefined;
	org_name: string | undefined;
	setShowDetailsAbonement: Dispatch<SetStateAction<AbonementType | null>>;
}

export const SliderAbonements: FC<SliderAbonementsProps> = ({platformId, setShowDetailsAbonement, org_name}) => {

	const [abonements, setAbonements] = useState<AbonementType[]>([]);

	useEffect(() => {
		SearchPlatformAPI.getListAbonements({
			platform_id: platformId ? [platformId] : undefined,
			org_name: org_name ?  [org_name] : undefined,
		}).then(res => {
			setAbonements(res.data.abonements);
		});
	}, [platformId]);

	const flickityOptions = () => {
		return {
			cellAlign: 'center',
			freeScroll: true,
			initialIndex: 1,
		};
	};

	return (
		<SC.FlickityContainer>
			<SC.FlickityWrapper>
				<Flickity
					className={SC.Slider}
					elementType="div"
					disableImagesLoaded
					options={flickityOptions()}
				>
					{abonements.map((item) => {
						return (
							<div key={item.id} style={{height: '200px'}}>
								<StillAbonementCard abonement={item} setShowDetailsAbonement={setShowDetailsAbonement}/>
							</div>
						);
					})}
				</Flickity>
			</SC.FlickityWrapper>
		</SC.FlickityContainer>
	);
};
