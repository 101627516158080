import {FC, memo} from 'react';

import * as SC from './link-with-border.style';

interface Props {
	click?: () => void;
	label: string;
	borderColor?: 'yellow' | 'green' | 'purple';
	className?: string;
	path: string
}

export const LinkWithBorder: FC<Props> = memo(({label, click, borderColor = 'green', className, path}) => {
	return (
		<SC.Container to={path} onClick={click} className={className} borderColor={borderColor}>{label}</SC.Container>
	);
});

