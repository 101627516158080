import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ContactsList  = css`
	margin-top: 24px;
`;

export const ContactsListItemContent = css`
	display: flex;
	justify-content: space-between;
	overflow: hidden;

	gap: 5px;
`;

export const ContactsListItemLabel = styled.span`
	overflow: hidden;
	font-size: 30px;
	line-height: 1.36em;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const Link = styled.a`
	overflow: hidden;
	font-size: 30px;
	line-height: 1.36em;
	white-space: nowrap;
	text-overflow: ellipsis;

	@media (hover: hover) {
		&:hover {
			color: var(--green);
		}
	}
`;
export const LinkStyle = css`
	font-size: 30px !important;
`;
