import classNames from 'classnames';
import {FC, useCallback} from 'react';
import BasePagination from 'react-paginate';
import {useLocation, useNavigate} from 'react-router-dom';

import IconArrowRight from '../svg-icon-components/icon-arrow-right';
import IconDoubleArrowRight from '../svg-icon-components/icon-double-arrow-right';
import {FIRST_PAGE} from './constants';
import styles from './pagination.module.css';

interface PaginationProps {
	className?: string;
	page: number;
	pageCount: number;
	perPage: number;
	link?: string;
	onPageChange: (nextPage: number) => void;
}

export const Pagination: FC<PaginationProps> = ({className, pageCount, link, page, onPageChange}) => {
	const isFirstPage = page === FIRST_PAGE;
	const isLastPage = page === pageCount;
	const navigate = useNavigate();
	const location = useLocation();

	const handleOnPageChange = useCallback(({selected}: { selected: number }) => {
		const params = new URLSearchParams(location.search);
		const startPath = location.pathname.split('/')[1];
		const basePath = `/${link || startPath}`;
		const pagePath = selected !== 0 ? `/page${selected + 1}` : '';
		navigate(`${basePath}${pagePath}?${params.toString()}`);
		onPageChange(selected + 1);
	}, [location, link, navigate, onPageChange]);

	const lastPageCountClick = useCallback(() => {
		const params = new URLSearchParams(location.search);
		const startPath = location.pathname.split('/')[1];
		const basePath = `/${link || startPath}`;
		const pagePath = pageCount !== 0 ? `/page${pageCount}` : '';

		navigate(`${basePath}${pagePath}?${params.toString()}`);
		onPageChange(pageCount);
	}, [location, link, pageCount, navigate, onPageChange]);

	const firstPageCountClick = useCallback(() => {
		const params = new URLSearchParams(location.search);
		const startPath = location.pathname.split('/')[1];
		const basePath = `/${link || startPath}`;
		navigate(`${basePath}?${params.toString()}`);
		onPageChange(FIRST_PAGE);
	}, [location, link, navigate, onPageChange]);

	if (pageCount <= FIRST_PAGE) {
		return null;
	}

	return (
		<div className={classNames(styles.pagination_container, className)}>
			{!isFirstPage ? (
				<div
					className={classNames(styles.base_link, styles.page_link)}
					onClick={firstPageCountClick}
				>
					<IconDoubleArrowRight className={styles.first_page_label}/>
				</div>
			) : null}
			<BasePagination
				breakLabel="..."
				nextLabel={<IconArrowRight/>}
				onPageChange={handleOnPageChange}
				pageRangeDisplayed={3}
				marginPagesDisplayed={1}
				forcePage={page - 1}
				pageCount={pageCount}
				previousLabel={<IconArrowRight className={styles.previous_label}/>}
				renderOnZeroPageCount={null}
				containerClassName={styles.pagination}
				disabledClassName={styles.button_disabled}
				activeClassName={styles.page_active}
				breakLinkClassName={classNames(styles.base_link, styles.break_link)}
				previousLinkClassName={classNames(styles.base_link, styles.page_link)}
				pageLinkClassName={classNames(styles.base_link, styles.page_link)}
				nextLinkClassName={classNames(styles.base_link, styles.page_link)}
				activeLinkClassName={styles.page_link_active}
			/>
			{!isLastPage ? (
				<div
					className={classNames(styles.base_link, styles.page_link)}
					onClick={lastPageCountClick}
				>
					<IconDoubleArrowRight/>
				</div>
			) : null}
		</div>
	);
};
