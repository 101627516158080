import styled from '@emotion/styled';

export const Container  = styled.button`
	width: fit-content;
	color: var(--border-B);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	padding: 0 0 4px 0;
	border-bottom: 1px solid var(--border-B);
	transition: 0.3s;

	@media (hover: hover) {
		&:hover {
			color: var(--button-Hover-B-Primary);
		}
	}
`;
