import {FC, memo} from 'react';

import * as SC from './button-with-line-bottom.style';

interface Props {
	label: string;
	className?: string;
	click?: () => void;
}

export const ButtonWithLineBottom: FC<Props> = memo(({click, label, className}) => {
	return (
		<SC.Container className={className} onClick={click}>{label}</SC.Container>
	);
});
