import {FC, memo} from 'react';
import {useForm} from 'react-hook-form';

import {useAppDispatch} from '../../../redux/store';
import {fetchRegisterOrgRequest} from '../../../redux/thunks/add-register-org-request';
import {isValidPhoneNumber} from '../../../shared/utils/is-valid-input- phone-email';
import {Button} from '../../buttons';
import {Input} from '../../controls';
import PhoneInput from '../../controls/phone-input/phone-input';
import {AcceptConditionsOrg} from '../../registration-conditions/registration-conditions';
import IconCallShape from '../../svg-icon-components/icon-call-shape';
import IconEmailShape from '../../svg-icon-components/icon-email-shape';
import IconPlatformShape from '../../svg-icon-components/icon-platform-shape';
import * as SC from './form-application-connection-organization.style';

interface FormApplicationConnectionOrgProps {
	page: 'main' | 'quote'
}

export const FormApplicationConnectionOrganization: FC<FormApplicationConnectionOrgProps> = memo(({page}) => {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const dispatch = useAppDispatch();
	const {register, handleSubmit, reset, watch} = useForm();
	const isDisabledBtnSubmit =
		!isValidPhoneNumber(watch().phone) || watch().org_name === '' || watch().user_email === '' || watch().unp === '' || !emailRegex.test(watch().user_email);

	const onSubmit = async () => {
		const user_phone = watch().phone.replace(/^|[\s()\-]/g, '');
		dispatch(fetchRegisterOrgRequest({
			user_phone,
			org_name: watch().org_name.trim(),
			user_email: watch().user_email.trim(),
			unp: watch().unp.trim(),
		})).unwrap().then(res => {
			if (res?.status === 200) {
				reset();
			}
		});
	};
	return (
		<SC.Form page={page} onSubmit={handleSubmit(onSubmit)}>
			<SC.FormLabel>Оставьте завку на <SC.Free>быстрое</SC.Free> подключение</SC.FormLabel>
			<SC.InputWrapper page={page}>
				<Input
					{...register('org_name', {
						required: true,
					})}
					value={watch().org_name}
					style={SC.InputCss}
					type="text"
					icon={<IconPlatformShape/>}
					placeholder="Название клуба"
					required={true}
				/>
				<Input
					{...register('unp', {
						required: true,
					})}
					value={watch().unp}
					style={SC.InputCss}
					type="number"
					icon={<IconEmailShape/>}
					placeholder="УНП"
					required={true}
				/>
				<Input
					{...register('user_email', {
						required: true,
						pattern: {
							value: emailRegex,
							message: 'Неверный формат E-mail',
						},
					})}
					value={watch().user_email}
					style={SC.InputCss}
					type="email"
					icon={<IconEmailShape/>}
					placeholder="E-mail"
					required={true}
				/>
				<PhoneInput
					data-tooltip-id="phone"
					{...register('phone', {
						required: true,
					})}
					value={watch().phone}
					type="tel"
					icon={<IconCallShape/>}
					placeholder="Телефон"
					required={true}
				/>
				<Button label={'Оставить заявку'} onClick={onSubmit} disabled={isDisabledBtnSubmit}
						style={SC.ButtonGreenCss} disabledGgColorMode={'dark'} bgColor={'green'}/>
			</SC.InputWrapper>
			<AcceptConditionsOrg/>
		</SC.Form>
	);
});
