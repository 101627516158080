import {FC, memo} from 'react';

import filter from '../../../assets/icon/filter.svg';
import * as SC  from './button-filter.style';

interface Props{
	onClick: ()=>void;
	className?: string;
}

export const ButtonFilter:FC<Props> = memo(({onClick, className}) => {
	return (
		<SC.Container onClick={onClick} className={className}>
			<span>Фильтры</span>
			<img src={filter} alt={'filter'}/>
		</SC.Container>
	);
});
