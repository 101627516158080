import {ImageInfo, UniqueImage} from '../components/images/index.type';
import {AbonementInfo} from './abonement';
import {OrderType} from './enums';
import {Contacts, LocationInfo, ScheduleInfo} from './interfaces';

export interface OrderStepPolicy {
	min_order_duration: number;
	service_step: number;
	order_step: number;
}

export interface OrderPolicy {
	allows: `${OrderType}`;
	attachable: boolean;
	trial_order: number | null;
	max_date_delay: number | null;
	max_user_hours: number | null;
	duration_free: boolean,
	max_user_services: number | null;
	periodic: OrderStepPolicy | null;
}

export type SexKind = 'Both' | 'Male' | 'Female';

export interface AddService {
	sports: string[],
	images: ImageInfo[],
	description: string;
	max_participants: number;
	order_policy: OrderPolicy;
	platform_id: string;
	schedule: ScheduleInfo[];
	service_name: string;
	service_type: string;
	service_kind: string;
	item_name: string | null;
	shared: boolean;
	age_from: number | null,
	age_to: number | null,
	sex: SexKind,
	entry_level: string | null,
	price: number;
}

export interface UpdateService extends AddService {
	id: string;
}

export type ServicePlatformInfo = {
	location_info: LocationInfo;
	city: string | null;
	platform_id: string;
	contacts: Contacts;
};

export type ServiceOrgInfo = { logo: UniqueImage[]; org_name: string };

export interface IService {
	id: string;
	description: string;
	max_participants: number;
	order_policy: OrderPolicy;
	service_name: string;
	service_type: string;
	sports: string[];
	origin_for: string;
	service_kind: string;
	platform_info: ServicePlatformInfo;
	org_info: ServiceOrgInfo;
	schedule: ScheduleInfo[];
	active: boolean;
	age_from: number | null;
	age_to: number | null;
	deps: number;
	price: number;
	sex: SexKind,
	entry_level: string | null,
	abonements: AbonementInfo[],
}

export interface ServiceSingle extends IService {
	images: UniqueImage[];
}

export interface ServiceListItem extends IService {
	logo: UniqueImage[];
}

export enum ServiceListFilterKey {
	platform_id = 'platform_id',
	sports = 'sports',
	org_name = 'org_name',
	service_type = 'service_type',
	service_kind = 'service_kind',
	city = 'city',
	date = 'date',
	from = 'from',
	to = 'to',
	duration = 'hours',
	amount = 'amount',
	kind = 'kind',
	activity = 'activity',
	limit = 'limit',
}

export enum AbonementsListFilterKey {
	id = 'id',
	platform_id = 'platform_id',
	sports = 'sports',
	org_name = 'org_name',
	service_type = 'service_type',
	kind = 'kind',
	city = 'city',
	limit = 'limit',
	unlimited = 'unlimited',
	price_from = 'price_from',
	price_to = 'price_to',
}

// Quick order page
export type OrderServicesFilters = {
	date: string;
	from: string;
	to: string;
	kind: string;
	sports: string;
	service_type: string;
	org_name: string;
	hours: string;
	amount: string;
};

export interface ServiceFilterInfo {
    service_type: string,
    service_kind: string,
    sports: {sport: string, sport_group: string | null}[],
}