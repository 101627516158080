import styled from '@emotion/styled';

export const ButtonGroup = styled.div`
	display: flex;
	gap: 10px;
	button, a{
		width: 100%;
	}
`;
export const ButtonWrapper = styled.div`
	position: relative;
	width: 100%;
`;
export const ConfirmDeletion = {
	top: '-90px',
};
