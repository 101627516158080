import {FC} from 'react';

import IconBurgerMenu from '../../../../../components/svg-icon-components/icon-burder-menu';
import styles from './burger-menu-button.module.css';

interface BurgerMenuButtonProps {
	onClick: () => void;
}

export const BurgerMenuButton: FC<BurgerMenuButtonProps> = ({onClick}) => {
	return (
		<button type="button" className={styles.burger_menu_button} onClick={onClick}>
			<IconBurgerMenu />
		</button>
	);
};
