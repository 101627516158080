import {FC, memo, useCallback, useMemo, useState} from 'react';

import {OrderType} from '../../../../../interfaces/enums';
import {ServiceListItem} from '../../../../../interfaces/service';
import {howOrderInfo} from '../../../../../pages/schedule-page/utils/how-order-info';
import {ABONEMENT, SERVICE_PAGE} from '../../../../../shared/constants/path';
import {ButtonNew} from '../../../../buttons-new';
import {Link, LinkWithBorder} from '../../../../links-navigate';
import * as SC from './buttons-navigates.style';

interface Props {
	service: ServiceListItem;
}

export const ButtonsNavigates: FC<Props> = memo(({service}) => {
	const [showPhone, setShowPhone] = useState(false);
	const platform_info = service.platform_info;
	const order_policy = service.order_policy;
	const pathToServicePage = `${SERVICE_PAGE}?service_id=${service.id}&sports=${service.sports[0]}&service_type=${service.service_type}&platform_id=${platform_info.platform_id}`;

	const clickShowPhone = useCallback(() => setShowPhone(prevState => !prevState), []);

	const crossToAbonementsParams = useMemo(() => {
		const platform_id: string = service.platform_info.platform_id;
		const service_type: string = service.service_type;
		const service_kind: string = service.service_kind;
		const sport_types: string[] = service.sports;
		const sportsQuery = sport_types.join(',');
		return new URLSearchParams({
			platform_id, service_type, service_kind, sport_types: sportsQuery,
		}).toString();
	}, [service]);

	const orderWithTrial = useCallback(() => {
		sessionStorage.setItem('scrollToMap', 'true');
		localStorage.setItem('trial_order', service.id);
	}, []);

	return (
		<>
			<LinkWithBorder path={pathToServicePage} label={'Подробнее'}
							className={SC.OrderButton}/>
			{order_policy.allows === OrderType.abonementOnly &&
				<>
					{order_policy.trial_order === null
						? (service.abonements.length > 0 ?
							<Link path={`/${ABONEMENT}?${crossToAbonementsParams}`} label={'Перейти к абонементам'}
								  className={SC.OrderButton} bgColor={'purple'}/> : null)
						: <Link path={pathToServicePage} label={'Перейти к расписанию'}
								className={SC.OrderButton} bgColor={'purple'}
								click={orderWithTrial}/>}
				</>}
			{order_policy.allows === OrderType.book || service.order_policy.allows === OrderType.request &&
				<Link
					path={pathToServicePage}
					label={'Перейти к расписанию'}
					className={SC.OrderButton} bgColor={'purple'}
					click={() => sessionStorage.setItem('scrollToMap', 'true')}/>}
			{order_policy.allows === OrderType.view &&
				<>
					{platform_info.contacts.viber.length === 0
						? <Link path={howOrderInfo(platform_info.contacts).contactLink || ''}
								label={howOrderInfo(platform_info.contacts).buttonText}
								className={SC.OrderButton} bgColor={'purple'}/>
						: <ButtonNew label={showPhone ? platform_info.contacts.viber[0] : 'Телефон'}
                                     onClick={clickShowPhone} className={SC.ButtonPhone}/>}
				</>}
		</>
	);
});
