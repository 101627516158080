import {Clock} from '../../../components/forms/form-add-service/constants/interfaces';
import {AbonementType} from '../../../interfaces/abonement';
import {MINUTES_COEFFICIENT, POINTS_PER_HOUR} from '../../constants/time';

export const calculateTimeInMinutes = (time: Clock) => {
	return +time.hours * 60 + +time.minutes;
};

export const isMultiple = (a: number, b: number) => {
	return a % b === 0;
};
export const converterTime = (countTime: number): string => {
	const hours = Math.trunc(countTime / POINTS_PER_HOUR).toString().padStart(2, '0');
	const minutesCount = countTime % POINTS_PER_HOUR;
	const minutes = String(minutesCount * MINUTES_COEFFICIENT).padStart(2, '0');
	return `${hours}.${minutes}`;
};
// 45 => 09.00
export const converterTimeOrderTable = (countTime: number): string => {
	const hours = Math.trunc(countTime / POINTS_PER_HOUR).toString();
	const minutesCount = countTime % POINTS_PER_HOUR;
	const minutes = String(minutesCount * MINUTES_COEFFICIENT).padStart(2, '0');

	if (countTime < POINTS_PER_HOUR) {
		return `${minutes}мин`;
	}
	if (countTime % POINTS_PER_HOUR === 0) {
		return `${hours}ч`;
	}
	return `${hours}ч. ${minutes}мин`;
};
export const coderTimeIn5 = (time: { hours: string, minutes: string }): number => {
	// hour * 12 + minutes % 5
	const hour: number = +time.hours * POINTS_PER_HOUR;
	const min = +time.minutes / MINUTES_COEFFICIENT;
	return hour + min;
};// 9.00 => 36
export const decodeTimeFrom5 = (value: number): Clock => {
	const hours = Math.floor(value / POINTS_PER_HOUR).toString();
	const minutes = ((value % POINTS_PER_HOUR) * MINUTES_COEFFICIENT).toString().padStart(2, '0');
	return {hours, minutes};
};

export const coderTimeIn5FromString = (timeString: string): number => {
	const [hoursStr, minutesStr] = timeString.split('.');
	const hours = parseInt(hoursStr);
	const minutes = parseInt(minutesStr);

	// hour * INTERVALS_PER_HOUR + minutes / MINUTES_COEFFICIENT
	const hour: number = hours * POINTS_PER_HOUR; // 1 час = INTERVALS_PER_HOUR интервала по MINUTES_COEFFICIENT минут
	const min = minutes / MINUTES_COEFFICIENT; // MINUTES_COEFFICIENT минут = 1 интервал

	return hour + min;
}; // 9.00 => 36

export const converterTimeLocalString = (date: Date): string => {
	return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
};
// => '2023-06-01'
export const convertToISOFormatPlusTime = (date: Date, time: string) => {
	const years = date.getFullYear();
	const months = String(date.getMonth() + 1).padStart(2, '0');
	const days = String(date.getDate()).padStart(2, '0');
	const [hour, minute] = time.split('.');
	const formattedHours = String(hour).padStart(2, '0');
	const formattedMinutes = String(minute).padStart(2, '0');

	return `${years}-${months}-${days}T${formattedHours}:${formattedMinutes}`;
};// => 2024-11-19T09:30:00

export const converterTimeString = (date: string): string => {
	if (!date) {
		return '';
	}
	return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(0, 4)}`;
}; // 2023-05-21 => 21.05.2023
export const converterTimeToDayMouth = (date: string): string => {
	return `${date.slice(8, 10)}.${date.slice(5, 7)}.`;
};// 2023-05-21 => 21.05.
export const converterDatetimeString = (date: string): string => {
	return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(0, 4)}, ${date.slice(11, 13)}:${date.slice(14, 16)}`;
};  // 2023-12-28T18:28 => 18:28 28.12.2023


export const getAbonementTime = (abonement: AbonementType): string | undefined => {
	if (abonement.info.working_from !== null && abonement.info.working_to !== null) {
		return `с: ${converterTime(abonement.info.working_from).replace('.', ':')}
			до: ${converterTime(abonement.info.working_to).replace('.', ':')}`;
	}

	if (abonement.info.working_from !== null) {
		return `с: ${converterTime(abonement.info.working_from).replace('.', ':')}`;
	}

	if (abonement.info.working_to !== null) {
		return `до: ${converterTime(abonement.info.working_to || 0).replace('.', ':')}`;
	}

	return undefined;
};