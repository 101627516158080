import {OrderPolicy} from '../../../../interfaces/service';
import {POINTS_PER_15_MIN, POINTS_PER_HOUR} from '../../../../shared/constants/time';

export const initialReservationData:OrderPolicy = {
	allows: 'View',
	attachable: false,
	max_date_delay: null,
	max_user_hours: null,
	max_user_services: null,
	periodic: null,
	trial_order: null,
	duration_free: false,
};

export const initialTimeSelect = {
	hours: '0',
	minutes: '15'
};
export const newPeriodic = {
	min_order_duration: POINTS_PER_HOUR,
	service_step: POINTS_PER_15_MIN,
	order_step: POINTS_PER_15_MIN,
};

export const SEX_SERVICES = {
	Both: 'Мужской и Женский',
	Male: 'Мужской',
	Female: 'Женский',
};
export const getKeyByValue = (value: string): keyof typeof SEX_SERVICES => {
	const entry = Object.entries(SEX_SERVICES).find(([, val]) => val === value);
	return entry ? entry[0] as keyof typeof SEX_SERVICES : 'Both';
};
