import styled from '@emotion/styled';

export const LabelLink = styled.a`
	font-weight: 400;
	font-size: 16px;
	line-height: 1.36em;
	color: var(--color-option);
	transition: 0.3s;

	@media (hover: hover) {
		&:hover{
			color: var(--green);
		}
	}
`;
export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;
