import {Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState} from 'react';

import vector_icon from '../../../../../assets/icon/icon_down_btn.svg';
import {AbonementType} from '../../../../../interfaces/abonement';
import {ServiceListItem} from '../../../../../interfaces/service';
import {isPlatformAbonement} from '../../../../../shared/utils/is-platform-abonement';
import {getAbonementTime} from '../../../../../shared/utils/time/converter-time';
import {getMonthsLabel, getWeeksLabel} from '../../../../../shared/utils/transform-text-label';
import {ModalWindow} from '../../../../modal-window/modal-window';
import IconTime from '../../../../svg-icon-components/icon-time';
import {IconLock} from '../../../../svg-icon-components/Lock';
import {ByeButton} from '../bye-button/bye-button';
import {DetailWrapper} from '../detail-wrapper/detail-wrapper';
import {Name} from '../name/name';
import {OrgInfo} from '../org-info/org-info';
import {ServiceCard} from '../service-card/service-card';
import {SliderAbonements} from '../slider-abonements/slider-abonements';
import * as SC from './modal-details-abonement.style';

interface ModalDetailsAbonement {
	abonement: AbonementType
	closeWindow: () => void
	setOpenPhoneWindow: Dispatch<SetStateAction<boolean>>;
	setShowDetailsAbonement: Dispatch<SetStateAction<AbonementType | null>>;
	services: ServiceListItem[]
}

export const ModalDetails: FC<ModalDetailsAbonement> = ({
															abonement,
															closeWindow,
															setOpenPhoneWindow,
															services,
															setShowDetailsAbonement
														}) => {
	const BG_ICON = 'var(--yellow)';
	const [isExpanded, setIsExpanded] = useState(false);
	const [shouldShowButton, setShouldShowButton] = useState(false);
	const textRef = useRef(null);

	const toggleExpand = () => setIsExpanded(prevState => !prevState);
	useEffect(() => {
		// Проверяем, превышает ли текст 3 строки
		if (textRef.current) {
			const {scrollHeight, clientHeight} = textRef.current;
			setShouldShowButton(scrollHeight > clientHeight);
		}
	}, [abonement.info.description]);

	const orgLogo = useMemo(() => abonement.org_info.logo.find(image => image.info.size_label === '1:1')?.info.image_link, [abonement.id]);
	const timeLabel = getAbonementTime(abonement);
	return (
		<ModalWindow closeWindow={closeWindow} styleWindow={SC.Window}>
			<SC.ContentWrapper>
				<Name title={abonement.info.name}/>
				<SC.Hr/>
				<OrgInfo logo={orgLogo} orgName={abonement.org_info.org_name}
						 address={isPlatformAbonement(abonement.info.issuer) && abonement.platform_info
							 ? `${abonement.platform_info.city}, ${abonement.platform_info.location_info.address}` : 'Клубная карта'}/>
				<SC.DataContainer>
					<DetailWrapper label={'Количество занятий'} icon={<IconLock backgroundColor={BG_ICON}/>}
								   text={abonement.info.limit !== null ? abonement.info.limit.toString() : 'Безлимитно'}
								   unLimit={abonement.info.limit === null}/>
					<DetailWrapper label={'Длительность абонемента'} icon={<IconTime backgroundColor={BG_ICON}/>}
								   text={'Month' in abonement.info.duration
									   ? `${abonement.info.duration.Month} ${getMonthsLabel(abonement.info.duration.Month)}`
									   : `${abonement.info.duration.Week} ${getWeeksLabel(abonement.info.duration.Week)}`}/>
					<SC.TextHoverWrapper>
						{timeLabel &&
							<DetailWrapper label={'Время для посещения'} icon={<IconTime backgroundColor={BG_ICON}/>}
										   text={timeLabel}/>}
					</SC.TextHoverWrapper>
				</SC.DataContainer>
				{services.length > 0 && <SC.HasAbonement>Доступно в абонементе:</SC.HasAbonement>}
				<SC.ServiceCardWrapper>
					{services.map(service => {
						const serviceLogo = service.logo.find(image => image.info.size_label === '16:9')?.info.image_link;
						return <ServiceCard key={service.id} title={service.service_type} logo={serviceLogo}
											id={service.id}/>;
					})}
				</SC.ServiceCardWrapper>
				<SC.DescriptionWrapper>
					<SC.Description isExpanded={isExpanded} ref={textRef}>{abonement.info.description}</SC.Description>
					{shouldShowButton && (
						<SC.ShowDescription
							onClick={toggleExpand}>
							<span>{isExpanded ? 'Скрыть' : 'Показать полностью'}</span>
							<SC.ShowDescriptionIcon isExpanded={isExpanded} src={vector_icon} alt="vector_icon"/>
						</SC.ShowDescription>
					)}
				</SC.DescriptionWrapper>
				{!(abonement.payment_method === 'CASH') &&
					<ByeButton id={abonement.id} setOpenPhoneWindow={setOpenPhoneWindow} price={abonement.info.price}/>}
				<div>
					<SC.HasAbonement>Еще абонементы:</SC.HasAbonement>
					<SliderAbonements
						platformId={isPlatformAbonement(abonement.info.issuer) && abonement.platform_info ? abonement.platform_info.platform_id : undefined}
						org_name={!isPlatformAbonement(abonement.info.issuer) ? abonement.org_info.org_name : undefined}
						setShowDetailsAbonement={setShowDetailsAbonement}/>
				</div>
			</SC.ContentWrapper>
		</ModalWindow>
	);
};
