import {FC, memo} from 'react';

import * as SC from './button-new.style';

interface Props {
	onClick?: () => void;
	label: string;
	bgColor?: 'yellow' | 'green' | 'purple';
	className?: string;
	disabled?: boolean;
}

export const ButtonNew: FC<Props> = memo(({bgColor = 'green', onClick, label, className, disabled}) => {
	return (
		<SC.Container bgColor={bgColor} onClick={onClick} className={className}
					  disabled={disabled}>{label}</SC.Container>
	);
});
