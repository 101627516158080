import {getEmptyArray} from '../../utils/get-empty-array';

// Amount filter

const AMOUNT_FILTER_OPTION_COUNT = 15;

export const AMOUNT_FILTER_OPTIONS = getEmptyArray(AMOUNT_FILTER_OPTION_COUNT).map((index) => {
	const order = index + 1;

	return {
		value: String(order),
		label: String(order),
	};
});

// Service kind filter

export const ANY_ACTIVITY_OPTION = {
	value: 'Любая активность',
	label: 'Любая активность',
};

export const ONLY_ACTIVE_OPTION = {
	value: 'Активные',
	label: 'Активные',
};
export const ONLY_NOT_ACTIVE_OPTION = {
	value: 'Не активные',
	label: 'Не активные',
};

// Activity filter
export const ACTIVITY_OPTIONS = [
	ANY_ACTIVITY_OPTION,
	ONLY_ACTIVE_OPTION,
	ONLY_NOT_ACTIVE_OPTION,
];
