import styled from '@emotion/styled';
import {NavLink} from 'react-router-dom';

export const FooterContainer = styled.footer`
	background-color: var(--Green-Solid-50);
	display: flex;
	flex-direction: column;
`;
export const NavFooter = styled.nav`
	margin: 0 auto;
	padding: 24px 0 8px 0;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 24px;
	justify-content: flex-start;
	@media screen and (max-width: 840px){
		flex: 1 1 45%;
		max-width: 450px;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		gap: 32px 50px;
	}
	@media screen and (max-width: 600px){
		flex-direction: column;
		align-items: center
	}
`;
export const BlockNav = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	justify-content: flex-start;
	@media screen and (max-width: 840px){
		width: fit-content;
	}
`;

export const Title = styled.h2`
	color: var(--text-Strong);
	text-align: left;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	@media screen and (max-width: 1100px){
		text-align: center;
	}
	@media screen and (max-width: 840px){
		text-align: left;
	}
	@media screen and (max-width: 600px){
		text-align: center;
	}
`;
export const TitleContacts = styled.h2`
	color: var(--text-Strong);
	text-align: left;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	@media screen and (max-width: 1100px){
		text-align: center;
	}
`;
export const Link = styled(NavLink)`
	color: var(--text-Strong);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	transition: 0.3s;
	text-wrap: nowrap;

	@media (hover: hover) {
		&:hover {
			color: var(--text-B);
		}
	}
`;
export const ListLink = styled.ul`
	display: flex;
	gap: 32px;
	@media screen and (max-width: 1100px){
		justify-content: center;
	}
	@media screen and (max-width: 840px){
		flex-direction: column;
		gap: 16px;
	}
	@media screen and (max-width: 600px){
		li{
			text-align: center;
		}
	}
`;
export const ListLinkContacts = styled.ul`
	display: flex;
	gap: 32px;
	@media screen and (max-width: 1100px){
		justify-content: center;
	}
	@media screen and (max-width: 840px){
		gap: 16px;
	}
`;
export const Payments = styled.img`
	width: 100%;
	background-color: var(--white-color);
	display: inline-block;
	animation: scroll 22s linear infinite;
	@keyframes scroll {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-100%);
		}
	}
`;
export const CompanyDetailsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	align-items: center;
	gap: 8px;
	@media screen and (max-width: 1100px){
		padding: 8px;
	}
	@media screen and (max-width: 600px){
		flex-direction: column;
	}
`;
export const ScrollContainer = styled.div`
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
`;

export const NavFooterWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	padding: 0 8px;
	@media screen and (max-width: 1100px){
		flex-direction: column-reverse;
	}
`;
export const CompanyDetailsWrapper = styled.div`
	display: flex;
	gap: 32px;
	max-width: 50%;
	padding: 0 8px;
	@media screen and (max-width: 1100px){
		flex-direction: column;
		gap: 8px;
	}
`;
export const TitleCompany = styled.h2`
	text-wrap: nowrap;
	color: var(--text-Weak);
	text-align: left;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	@media screen and (max-width: 600px){
		text-align: center;
	}
`;
export const CompanyAddress = styled.p`
	color: var(--text-Weak);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
	@media screen and (max-width: 600px){
		text-align: center;
	}
`;
export const RightsReserved = styled.p`
	color: var(--text-Weak);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
