import {createAsyncThunk} from '@reduxjs/toolkit';

import {OrgFiltersParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetAllOrgsFiltersThunk = createAsyncThunk(
	'filtersSlice/fetchGetAllFiltersThunk',
	async (filter: OrgFiltersParams, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getListAllOrgFilters(filter);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data.orgs};
			}
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
