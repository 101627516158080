import {FC, memo} from 'react';

import * as SC from './button-with-border.style';

interface Props {
	click?: () => void;
	label: string;
	borderColor?: 'yellow' | 'green' | 'purple';
	className?: string;
}

export const ButtonWithBorder: FC<Props> = memo(({label, click, borderColor = 'green', className}) => {
	return (
		<SC.Container onClick={click} className={className} borderColor={borderColor}>{label}</SC.Container>
	);
});
