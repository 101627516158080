import {createSlice} from '@reduxjs/toolkit';

import {OrganizationFilterInfo} from '../../interfaces/organization';
import {fetchGetAllOrgsFiltersThunk} from '../thunks/fllters/get-all-filters-thunk';
import {fetchGetOrgPlatformFilters} from '../thunks/fllters/get-org-platform-filters';

interface InitialState {
	orgs: OrganizationFilterInfo[]
	orgsAndPlatform: any;
}

const initialState: InitialState = {
	orgs: [],
	orgsAndPlatform: {},
};

export const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchGetAllOrgsFiltersThunk.fulfilled, (state, action) => {
			state.orgs = action.payload?.data;
		});
		builder.addCase(fetchGetOrgPlatformFilters.fulfilled, (state, action) => {
			state.orgs = action.payload?.data;
		});
	}
});

export const filters = filtersSlice.reducer;
