import {FC, memo} from 'react';
import {Link} from 'react-router-dom';

import no_image from '../../../../../assets/img/no_image.png';
import {ONE_ORG_NAME} from '../../../../../shared/constants/path';
import {generatedUrlOrgName} from '../../../../../shared/generated-url-org-name';
import * as SC from './org-info.style';

interface OrgInfoProps {
	logo: string | undefined;
	address: string
	orgName: string
}

export const OrgInfo: FC<OrgInfoProps> = memo(({orgName, address, logo}) => {
	return (
		<SC.OrgInfoWrapper>
			<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(orgName))}>
				<SC.LogoOrg src={logo || no_image} alt={orgName}/>
			</Link>
			<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(orgName))}>
				<SC.OrgNameLocationWrapper>
					<SC.OrgName>{orgName}</SC.OrgName>
					<SC.Adress>{address}</SC.Adress>
				</SC.OrgNameLocationWrapper>
			</Link>
		</SC.OrgInfoWrapper>
	);
});
