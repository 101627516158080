import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

export const AcceptConditions = styled.div`
	color: var(--black-color);
	font-size: 16px;
	font-weight: 400;
	line-height: 128.8%;
	text-align: left;
	margin-top: 1rem;
`;
export const AcceptConditionsLink = styled(Link)`
	color: var(--red-color);
`;

export const RegistrationConditions = styled.div`
	margin: 2rem 0;
	color: var(--primary-color);
	font-size: 16px;
	font-weight: 400;
	line-height: 128.8%;
	text-align: left;
`;

export const RegisterLink = styled.a`
	color: var(--red-color);
    cursor: pointer;
`;
